<template>
  <div>
    <CCard accentColor="primary">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{title}}
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow v-show="!formVisibility">
          <CCol md="12">
            <div role="group" class="form-group">
              <label class>Nama Perangkat Daerah</label>
              <v-select
                v-model="form.id_unit_kerja"
                :options="optionsOPD"
                label="nama_unit_kerja"
                :reduce="opd => opd.id_unit_kerja"
                :filterable="true"
                @search="onSearchOPD"
              ></v-select>
            </div>
          </CCol>
        </CRow>
        <CButtonGroup size="sm">
          <CButton color="info" v-show="!formVisibility" @click="loadData()">
            <CIcon name="cil-search" />Cari
          </CButton>
          <CButton color="warning" v-show="!formVisibility" @click="reset()">
            <CIcon name="cil-loop-circular" />Reset
          </CButton>
          <CButton color="success" v-show="!formVisibility" @click="exportExcel()">
            <CSpinner v-show="loading_export" size="sm" class="mr-2"/>
            <CIcon name="cil-file" v-show="!loading_export" class="mr-2" />Export
          </CButton>
        </CButtonGroup>
        <hr />
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          v-show="!formVisibility"
          :loading="loading"
        >
        <template #thead-top>
            <tr>
                <th rowspan="3" style="vertical-align: middle;" class="border-bottom">NO</th>
                <th rowspan="3" style="vertical-align: middle;" class="border-bottom">Nama Perangkat Daerah</th>
                <th colspan="17" class="text-center">Eselon</th>
                <th rowspan="3" style="vertical-align: middle;"  class="border-bottom">Total</th>
            </tr>
            <tr>
                <th colspan="4" class="text-center">I</th>
                <th colspan="4" class="text-center">II</th>
                <th colspan="4" class="text-center">III</th>
                <th colspan="5" class="text-center">IV</th>
            </tr>
        </template>
        </CDataTable>
        <CPagination
          :activePage.sync="page"
          :pages.sync="totalPage"
          size="sm"
          align="end"
          v-show="!formVisibility"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import VSelect from "vue-select";
import _ from "lodash";
import axios from "axios";
const fields = [
  { key: "number", label: "No", _style: 'display:none;' },
  { key: "nama_unit_kerja", label: "Nama OPD/SKPD", _style: 'display:none;' },
  { key: "jml_gol_1a", label: "I.a", _classes: 'text-center' },
  { key: "jml_gol_1b", label: "I.b", _classes: 'text-center' },
  { key: "jml_gol_1c", label: "I.c", _classes: 'text-center' },
  { key: "jml_gol_1d", label: "I.d", _classes: 'text-center' },
  { key: "jml_gol_2a", label: "II.a", _classes: 'text-center' },
  { key: "jml_gol_2b", label: "II.b", _classes: 'text-center' },
  { key: "jml_gol_2c", label: "II.c", _classes: 'text-center' },
  { key: "jml_gol_2d", label: "II.d", _classes: 'text-center' },
  { key: "jml_gol_3a", label: "III.a", _classes: 'text-center' },
  { key: "jml_gol_3b", label: "III.b", _classes: 'text-center' },
  { key: "jml_gol_3c", label: "III.c", _classes: 'text-center' },
  { key: "jml_gol_3d", label: "III.d", _classes: 'text-center' },
  { key: "jml_gol_4a", label: "IV.a", _classes: 'text-center' },
  { key: "jml_gol_4b", label: "IV.b", _classes: 'text-center' },
  { key: "jml_gol_4c", label: "IV.c", _classes: 'text-center' },
  { key: "jml_gol_4d", label: "IV.d", _classes: 'text-center' },
  { key: "jml_gol_4e", label: "IV.e", _classes: 'text-center' },
  { key: "total", label: "Total", _style: 'display:none;' },
];
export default {
  name: "PerEselon",
  components: {
    VSelect
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      formVisibility: false,
      loading: false,
      loading_export: false,
      page: 1,
      totalPage: 0,
      optionsOPD: [],
      form: {
        id_unit_kerja: null,
      },
    };
  },
  watch: {
    page: function () {
      this.loadData();
    },
  },
  computed: {
    computedItems() {
      var number = 1;
      let total_pages = 10 * (this.page - 1);
      return this.items.map((item) => {
        var total_gol = parseInt(item.jml_gol_1a) + parseInt(item.jml_gol_1b) + parseInt(item.jml_gol_1c) + parseInt(item.jml_gol_1d) + parseInt(item.jml_gol_2a) + parseInt(item.jml_gol_2b) + parseInt(item.jml_gol_2c) + parseInt(item.jml_gol_2d) + parseInt(item.jml_gol_3a) + parseInt(item.jml_gol_3b) + parseInt(item.jml_gol_3c) + parseInt(item.jml_gol_3d) + parseInt(item.jml_gol_4a) + parseInt(item.jml_gol_4b) + parseInt(item.jml_gol_4c) + parseInt(item.jml_gol_4d) + parseInt(item.jml_gol_4e);
        return {
          ...item,
          number: number++ + total_pages,
          total: total_gol,
        };
      });
    },
    title() {
      return "REKAPITULASI PNS BERDASARKAN ESELON";
    },
  },
  mounted() {
    this.loadData();
    this.searchOPD("", this);
  },
  methods: {
    reset() {
      this.form.id_unit_kerja = null;
      this.page = 1;
      this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;
        let data = await this.$store.dispatch("laporan_per_eselon/index", {
          page: this.page,
          data: this.form,
        });
        this.items = data.data;
        this.totalPage = data.last_page;
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
    resetTabel() {
      this.loadData();
    },
    async onSearchOPD(search, loading) {
      loading(true);
      await this.searchOPD(search, this);
      loading(false);
    },
    searchOPD: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "laporan_per_opd_skpd/autocomplete_opd_skpd",
        search
      );
      if (search) vm.optionsOPD = options;
    }, 300),
    async exportExcel(){
      this.loading_export = true;
      await axios.get('/api/laporan/per-eselon/export?id_unit_kerja='+this.form.id_unit_kerja,{responseType: 'arraybuffer'})
      .then(response => {
        const current = new Date();
        const yyyy = current.getFullYear();
        let mm = current.getMonth() + 1;
        let dd = current.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const date = dd + '' + mm + '' + yyyy;
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'PerEselon_'+date+'.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loading_export = false;
      })
    },
  },
};
</script>
<style scoped>
.border-bottom{
    border-bottom: 2px solid !important;
    border-bottom-color: #d8dbe0 !important;
}
</style>